export const PRODUCT = 'PRODUCT';
export const PRODUCT_WEBHOOK = 'PRODUCT_WEBHOOK';
export const PRODUCT_ACK_PREFERENCE = 'PRODUCT_ACK_PREFERENCE';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';

export const getProduct = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PRODUCT,
    payload: {
      ...payload,
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const setWebhook = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PRODUCT_WEBHOOK,
    payload: {
      ...payload,
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const setAckPreference = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PRODUCT_ACK_PREFERENCE,
    payload: {
      ...payload,
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const setProduct = (payload) => ({
  type: PRODUCT_SUCCESS,
  payload,
});
