import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { withPostPutDeleteAsync } from '../api';
import { MESSAGE_SEND, setMessage } from '../actions';

function* withSendMessage({ payload }) {
  const { query, body } = payload;
  try {
    const resultParam = yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/${payload.phone_id}/sendMessage`,
      method: 'POST',
      body,
    });
    yield put(setMessage(resultParam));
  } catch (error) {
    console.log(error);
  }
}

export function* watchSendMessage() {
  yield takeEvery(MESSAGE_SEND, withSendMessage);
}

export default function* rootSaga() {
  yield all([fork(watchSendMessage)]);
}
