/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import phoneSagas from './phone/saga';
import messageSagas from './message/saga';
import productSagas from './product/saga';
import serverSagas from './server/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    phoneSagas(),
    messageSagas(),
    productSagas(),
    serverSagas(),
  ]);
}
