import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { withPostPutDeleteAsync, withloadAsync } from '../api';
import {
  PHONE,
  PHONE_CREATE,
  PHONE_DISABLED,
  PHONE_RESET,
  PHONE_LOGOUT,
  PHONE_CONFIG,
  PHONE_REMOVE,
  setPhone,
  listPhones,
} from '../actions';

function* withDataRemovePhone({ payload }) {
  const { query, body, token } = payload;
  try {
    yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/${payload.phone_id}/delete`,
      method: 'DELETE',
      body,
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataRemovePhone() {
  yield takeEvery(PHONE_REMOVE, withDataRemovePhone);
}

function* withDataConfigPhone({ payload }) {
  const { query, body, token } = payload;
  try {
    yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/${payload.phone_id}/config`,
      method: 'POST',
      body,
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataConfigPhone() {
  yield takeEvery(PHONE_CONFIG, withDataConfigPhone);
}

function* withDataLogoutPhone({ payload }) {
  const { query, token } = payload;
  try {
    yield call(withloadAsync, {
      query: `api/${query.pid}/${payload.phone_id}/logout`,
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataLogoutPhone() {
  yield takeEvery(PHONE_LOGOUT, withDataLogoutPhone);
}

function* withDataResetPhone({ payload }) {
  const { query, token } = payload;
  try {
    yield call(withloadAsync, {
      query: `api/${query.pid}/${payload.phone_id}/factoryreset`,
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataResetPhone() {
  yield takeEvery(PHONE_RESET, withDataResetPhone);
}

function* withDataDisabledPhone({ payload }) {
  const { query, token } = payload;
  try {
    yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/${payload.phone_id}/config`,
      method: 'POST',
      body: {
        status: 'disabled',
      },
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataDisabledPhone() {
  yield takeEvery(PHONE_DISABLED, withDataDisabledPhone);
}

function* withDataAddPhone({ payload }) {
  const { query, body, token } = payload;
  try {
    yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/addPhone`,
      method: 'POST',
      body,
      token,
    });
    yield put(listPhones());
  } catch (error) {
    console.log(error);
  }
}

function* watchDataAddPhone() {
  yield takeEvery(PHONE_CREATE, withDataAddPhone);
}

function* withDataListPhones({ payload }) {
  const { query, token } = payload;
  try {
    const resultParam = yield call(withloadAsync, {
      query: `api/${query.pid}/listPhones`,
      token,
    });
    if (Array.isArray(resultParam)) {
      yield put(setPhone(resultParam));
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchDataListPhones() {
  yield takeEvery(PHONE, withDataListPhones);
}

export default function* rootSaga() {
  yield all([
    fork(watchDataListPhones),
    fork(watchDataAddPhone),
    fork(watchDataDisabledPhone),
    fork(watchDataResetPhone),
    fork(watchDataLogoutPhone),
    fork(watchDataConfigPhone),
    fork(watchDataRemovePhone),
  ]);
}
