import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { withPostPutDeleteAsync, withloadAsync } from '../api';
import {
  PRODUCT,
  PRODUCT_WEBHOOK,
  // PRODUCT_ACK_PREFERENCE,
  setProduct,
} from '../actions';

function* withDataWebHook({ payload }) {
  const { query, body, token } = payload;
  try {
    const resultParam = yield call(withPostPutDeleteAsync, {
      query: `api/${query.pid}/setWebhook`,
      method: 'POST',
      body,
      token,
    });
    yield put(setProduct(resultParam));
  } catch (error) {
    console.error(error);
  }
}

function* watchDataWebHook() {
  yield takeEvery(PRODUCT_WEBHOOK, withDataWebHook);
}

function* withDataProduct({ payload }) {
  const { query, token } = payload;
  try {
    const resultParam = yield call(withloadAsync, {
      query: `api/${query.pid}/product`,
      token,
    });
    if (resultParam.pid) {
      yield put(setProduct(resultParam));
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchDataProduct() {
  yield takeEvery(PRODUCT, withDataProduct);
}

export default function* rootSaga() {
  yield all([fork(watchDataProduct), fork(watchDataWebHook)]);
}
