export const MESSAGE_SEND = 'MESSAGE_SEND';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';

export const sendMessage = (payload) => (dispatch, getState) => {
  return dispatch({
    type: MESSAGE_SEND,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
    },
  });
};

export const setMessage = (payload) => ({
  type: MESSAGE_SEND_SUCCESS,
  payload,
});
