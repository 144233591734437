export const callApi = (_endpoint, _request) => {
  return fetch(`/${_endpoint}`, _request)
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then((json) => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        });
      }
      if (response.ok) {
        return response
          .text()
          .then((text) => {
            try {
              const json = JSON.parse(text);
              if (json) {
                return json;
              }
            } catch (e) {
              // console.log(e);
            }
            return { responseText: text };
          })
          .catch((e) => {
            return { error: e };
          });
      }
      throw new Error(`${response.status} - ${response.statusText}`);
    })
    .catch((e) => {
      throw e;
    });
};

export const withloadAsync = ({ query, token }) =>
  callApi(query, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: token,
    },
  });

export const withPostPutDeleteAsync = ({ query, body, method, token }) =>
  callApi(query, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: token,
    },
    method,
    body: JSON.stringify(body),
  });
