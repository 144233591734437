import { PHONE_SUCCESS } from '../actions';

const INIT_STATE = {
  list: [],
};

export default (state = INIT_STATE, action) => {
  let index;
  let tempList;
  switch (action.type) {
    case PHONE_SUCCESS:
      index = state.list.slice().findIndex((f) => {
        return f.id === action.payload.id;
      });
      if (index !== -1 && !Array.isArray(action.payload)) {
        tempList = state.list.slice().splice(index, 1, action.payload);
      } else if (Array.isArray(action.payload)) {
        tempList = action.payload.slice();
      } else {
        tempList = [...state.list.slice(), action.payload];
      }
      return {
        ...state,
        list: tempList,
      };
    default:
      return { ...state };
  }
};
