import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import phones from './phone/reducer';
import messages from './message/reducer';
import product from './product/reducer';
import server from './server/reducer';

const appReducer = combineReducers({
  menu,
  settings,
  authUser,
  phones,
  messages,
  product,
  server,
});

const reducers = (state, action) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(state, action);
  }
  if (action.type === 'persist/REHYDRATE') {
    if (action.payload) return action.payload;
  }
  return appReducer(state, action);
};

export default reducers;
