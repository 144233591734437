import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { withloadAsync } from '../api';
import { SERVER, setServers } from '../actions';

function* withDataServer({ payload }) {
  const { token } = payload;
  try {
    const resultParam = yield call(withloadAsync, {
      query: 'api/server/list',
      token,
    });
    if (resultParam) {
      yield put(setServers(resultParam));
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchDataServer() {
  yield takeEvery(SERVER, withDataServer);
}

export default function* rootSaga() {
  yield all([fork(watchDataServer)]);
}
