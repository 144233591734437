export const SERVER = 'SERVER';
export const SERVER_SUCCESS = 'SERVER_SUCCESS';

export const listServers = (payload) => (dispatch, getState) => {
  return dispatch({
    type: SERVER,
    payload: {
      ...payload,
      token: getState().authUser.currentUser.token,
    },
  });
};

export const setServers = (payload) => ({
  type: SERVER_SUCCESS,
  payload,
});
