export const PHONE = 'PHONE';
export const PHONE_DISABLED = 'PHONE_DISABLED';
export const PHONE_CREATE = 'PHONE_CREATE';
export const PHONE_RESET = 'PHONE_RESET';
export const PHONE_SUCCESS = 'PHONE_SUCCESS';
export const PHONE_LOGOUT = 'PHONE_LOGOUT';
export const PHONE_CONFIG = 'PHONE_CONFIG';
export const PHONE_REMOVE = 'PHONE_REMOVE';

export const listPhones = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const addPhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_CREATE,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const disabledPhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_DISABLED,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const configPhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_CONFIG,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const logoutPhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_LOGOUT,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const resetPhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_RESET,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};

export const setPhone = (payload) => ({
  type: PHONE_SUCCESS,
  payload,
});

export const removePhone = (payload) => (dispatch, getState) => {
  return dispatch({
    type: PHONE_REMOVE,
    payload: {
      ...(payload || {}),
      query: {
        pid: getState().authUser.currentUser.pid,
      },
      token: getState().authUser.currentUser.token,
    },
  });
};
