import { MESSAGE_SEND_SUCCESS } from '../actions';

const INIT_STATE = {
  list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    default:
      return { ...state };
  }
};
